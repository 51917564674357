import "./style.css";
import RuaTay from "../Images/RuaTay.jpg";

const F1_QuyTrinhVST = ({}) => {
  return (
    <div id="section6-1" className="section">
      <div className="container">
        <h2>1. QUY TRÌNH VỆ SINH TAY</h2>
        <img
          src={RuaTay}
          width={"100%"}
          style={{ borderRadius: 10, marginBottom: 10 }}
          alt=""
        ></img>
      </div>
    </div>
  );
};
export default F1_QuyTrinhVST;
