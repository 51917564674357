import "./style.css";
import XetNghiemMau from "../Images/XetNghiemMau.png";

const E1_XetNghiemMau = ({}) => {
  return (
    <div id="section5-1" className="section">
      <div className="container">
        <h2>1. THỨ TỰ CHO MÁU VÀO CÁC ỐNG</h2>
        <img
          src={XetNghiemMau}
          width={"100%"}
          style={{ borderRadius: 10, marginBottom: 10 }}
          alt=""
        ></img>
      </div>
    </div>
  );
};
export default E1_XetNghiemMau;
