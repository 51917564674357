import "./style.css";
import XuTriPhoiNhiem from "../Images/XuTriPhoiNhiem.jpg";

const F9_XuTriPhoiNhiem = ({}) => {
  return (
    <div id="section6-9" className="section">
      <div className="container">
        <h2>9. XỬ TRÍ BAN ĐẦU KHI BỊ PHƠI NHIỄM</h2>
        <img
          src={XuTriPhoiNhiem}
          width={"100%"}
          style={{ marginBottom: 10 }}
          alt=""
        ></img>
      </div>
    </div>
  );
};
export default F9_XuTriPhoiNhiem;
