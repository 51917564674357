import "./style.css";
import SoDoTruyenKhoiHC from "../Images/SoDoTruyenKhoiHC.png";

const E2_SoDoTruyenKhoiHC = ({}) => {
  return (
    <div id="section5-2" className="section">
      <div className="container">
        <h2>2. SƠ ĐỒ TRUYỀN KHỐI HỒNG CẦU</h2>
        <img
          src={SoDoTruyenKhoiHC}
          width={"100%"}
          style={{ borderRadius: 10, marginBottom: 10 }}
          alt=""
        ></img>
      </div>
    </div>
  );
};
export default E2_SoDoTruyenKhoiHC;
